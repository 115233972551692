import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    BasicCircle,
    ArchWithCircle,
    BasicGrid,
    BasicSquare,
    WedgedArch
} from 'aphrodite-shared/SVGComponents/shapes';
import IwocaPayIcon from '../../../../SVGComponents/iwocaPayIcon';
import styles from './IwocaPayFormPage.module.scss';
import TrustPilotInlineRating from '../../../../components/TrustPilotInlineRating/TrustPilotInlineRating';
import TrustPilot from '../TrustPilot/TrustPilot';
import IwLink from '../../../../components/IwLink/IwLink';

const useTrustPilotData = () => {
    const { trustpilot } = useStaticQuery(
        graphql`
            {
                trustpilot {
                    ...trustPilot
                }
            }
        `
    );

    return trustpilot;
};

const IwocaPayFormPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6334445',
                    formId: 'd76ef53c-4376-476a-ac9f-5ea89325cdb8',
                    target: '#hubspotForm'
                });
            }
        });

        window.addEventListener('message', (event) => {
            if (
                event.data.type === 'hsFormCallback' &&
                event.data.eventName === 'onFormSubmit'
            ) {
                const el = event.data.data.find((el) => el.name === 'email');
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'iwocapay_hs_form_submit',
                        email: el.value
                    });
                }
            }
        });

        return function cleanup() {
            window.removeEventListener('message');
        };
    }, []);

    const data = useTrustPilotData();

    return (
        <div className={styles.IwocaPayFormPage}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <div className={styles.logoWrapper}>
                        <IwLink to="/iwocapay-sellers">
                            <IwocaPayIcon height="47" />
                        </IwLink>
                        <p className={styles.subtitle}>
                            Get paid up front and let your customers pay when
                            they want
                        </p>
                    </div>
                    <div className={styles.trustWrapper}>
                        <div className={styles.trustMobile}>
                            <div className={styles.trustPilot}>
                                <TrustPilot
                                    className={styles.logo}
                                    rating={data.score.trustScore}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="hubspotForm" className={styles.HubspotForm}>
                        <ArchWithCircle
                            className={styles.ArchWithCircle}
                            primaryColor="denim65"
                        />
                        <BasicCircle
                            className={styles.BasicCircle}
                            size="sm"
                            color="babyBlue"
                        />
                        <BasicGrid className={styles.BasicGrid} />
                        <BasicSquare className={styles.BasicSquare} size="sm" />
                        <WedgedArch
                            className={styles.WedgedArch}
                            size="sm"
                            color="snow"
                        />
                    </div>
                </div>
                <div className={styles.trust}>
                    <TrustPilotInlineRating
                        className={styles.trustPilot}
                        nameWidth="138"
                        starsWidth="150"
                    />
                    <p className={styles.rating}>
                        4.7 Rating • <span>Excellent</span>
                    </p>
                    <ul>
                        <li>
                            <div className={styles.tick}></div>
                            <p>Get paid in minutes</p>
                        </li>
                        <li>
                            <div className={styles.tick}></div>
                            <p>Give your customers 90 days to pay</p>
                        </li>
                        <li>
                            <div className={styles.tick}></div>
                            <p>Get started today</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default IwocaPayFormPage;
