import React from 'react';

const TrustPilotStars = ({ width = '137', style }) => (
    <svg
        style={style}
        width={width}
        height="27"
        viewBox="0 0 137 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M136.044 0.134766H110.645V26.3631H136.044V0.134766Z"
            fill="#00B67A"
        />
        <path
            d="M131.298 11.4013H125.221L123.344 5.43005L121.468 11.4013H115.391L120.311 15.0967L118.424 21.0679L123.344 17.3725L128.265 21.0679L126.388 15.0967L131.298 11.4013Z"
            fill="white"
        />
        <path
            d="M126.813 16.4484L126.388 15.0964L123.344 17.3723L126.813 16.4484Z"
            fill="#00B67A"
        />
        <path
            d="M108.441 0.134766H83.042V26.3631H108.441V0.134766Z"
            fill="#00B67A"
        />
        <path
            d="M103.695 11.4013H97.6183L95.7417 5.43005L93.8652 11.4013H87.7881L92.7087 15.0967L90.8212 21.0679L95.7417 17.3725L100.662 21.0679L98.7857 15.0967L103.695 11.4013Z"
            fill="white"
        />
        <path
            d="M99.2107 16.4484L98.7852 15.0964L95.7412 17.3723L99.2107 16.4484Z"
            fill="#00B67A"
        />
        <path
            d="M80.9462 0.134766H55.5469V26.3631H80.9462V0.134766Z"
            fill="#00B67A"
        />
        <path
            d="M76.2003 11.4013H70.1232L68.2466 5.43005L66.37 11.4013H60.293L65.2026 15.0967L63.326 21.0679L68.2466 17.3725L73.1672 21.0679L71.2797 15.0967L76.2003 11.4013Z"
            fill="white"
        />
        <path
            d="M71.7057 16.4484L71.2802 15.0964L68.2471 17.3723L71.7057 16.4484Z"
            fill="#00B67A"
        />
        <path
            d="M53.3427 0.134766H27.9434V26.3631H53.3427V0.134766Z"
            fill="#00B67A"
        />
        <path
            d="M48.5969 11.4013H42.5199L40.6433 5.43005L38.7558 11.4013H32.6787L37.5993 15.0967L35.7227 21.0679L40.6433 17.3725L45.5529 21.0679L43.6764 15.0967L48.5969 11.4013Z"
            fill="white"
        />
        <path
            d="M44.1021 16.4484L43.6766 15.0964L40.6436 17.3723L44.1021 16.4484Z"
            fill="#00B67A"
        />
        <path
            d="M25.7401 0.134766H0.34082V26.3631H25.7401V0.134766Z"
            fill="#00B67A"
        />
        <path
            d="M20.9942 11.4013H14.9171L13.0406 5.43005L11.164 11.4013H5.08691L9.99658 15.0967L8.11999 21.0679L13.0406 17.3725L17.9611 21.0679L16.0736 15.0967L20.9942 11.4013Z"
            fill="white"
        />
        <path
            d="M16.4996 16.4484L16.0741 15.0964L13.041 17.3723L16.4996 16.4484Z"
            fill="#00B67A"
        />
    </svg>
);

export default TrustPilotStars;
