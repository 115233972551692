import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TrustPilotName from '../../SVGComponents/TrustPilotName';
import TrustPilotStars from '../../SVGComponents/TrustPilotStars';
import classnames from 'classnames';
import styles from './TrustPilotInlineRating.module.scss';

const useTrustPilotData = () => {
    const { trustpilot } = useStaticQuery(
        graphql`
            {
                trustpilot {
                    ...trustPilot
                }
            }
        `
    );

    return trustpilot;
};

const TrustPilotInlineRating = ({
    className,
    nameWidth,
    starsWidth,
    trustpilotFill
}) => {
    const data = useTrustPilotData();

    return (
        <div className={classnames(styles.TrustPilotInlineRating, className)}>
            <a
                href="https://uk.trustpilot.com/review/iwoca.co.uk"
                target="_blank"
                rel="noopener noreferrer">
                <TrustPilotName
                    width={nameWidth}
                    trustpilotFill={trustpilotFill}
                />
                <div className={styles.stars}>
                    <TrustPilotStars width={starsWidth} />
                    <p>
                        <strong>{data.score.trustScore}</strong> out of 5
                    </p>
                </div>
            </a>
        </div>
    );
};

export default TrustPilotInlineRating;
